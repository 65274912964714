<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Que bom ter você na {{ $store.state.ie.shortname }}!
			</h2>

			<h3 class="subtitle">
				Faça seu login abaixo
			</h3>
		</div>

		<div class="columns is-centered mt-5">
			<div
				:class="[
					enviroment.isPrd ? 'is-4-fullhd' : 'is-5-fullhd',
					'column is-6-desktop'
				]"
			>
				<form
					novalidate
					class="form"
					@submit.prevent="handleLoginSubmit"
				>
					<div class="columns is-multiline">
						<div class="column is-12">
							<UIInput
								v-if="showOfficeLogin"
								id="cpf"
								v-model="form.cpf"
								v-mask="'###.###.###-##'"
								label="CPF"
								placeholder="CPF"
								:validation="$v.form.cpf"
								:error="{
									required: '*Campo obrigatório.',
									invalid: '*CPF inválido.',
								}"
								required
							>
								<template #addonAfter>
									<span class="button is-static">
										{{ $store.state.emailLoginDomain }}
									</span>
								</template>
							</UIInput>

							<UIInput
								v-else
								id="cpf"
								v-model="form.user"
								v-mask="'##############'"
								:label="labelMatricula"
								:placeholder="labelMatricula"
								:validation="$v.form.user"
								:error="{
									required: '*Campo obrigatório.',
								}"
								required
							/>
						</div>

						<div
							v-if="showLocalLogin"
							class="column is-12"
						>
							<UIInput
								id="senha"
								v-model="form.password"
								type="password"
								label="Senha"
								:validation="$v.form.password"
								:error="{
									minLength: '*A quantidade de caracteres é menor que 8.',
									invalid: '*Campo inválido.',
									required: '*Campo obrigatório.',
									strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
								}"
								required
							/>
						</div>

						<div class="column is-12">
							<router-link
								:to="{ name: 'login.recover' }"
								class="is-link is-block has-text-primary m-4"
							>
								Esqueci minha senha
							</router-link>

							<router-link
								v-if="showOfficeLogin && !showLocalLogin"
								:to="{ path: '/login/matricula' }"
								class="is-link is-block m-4 login-alternativo"
							>
								Tenho mais de uma matrícula
							</router-link>

							<router-link
								v-else-if="
									allowOfficeLogin
										&& !showOfficeLogin
										&& ($route.path != '/login')
								"
								:to="{ path: '/login' }"
								class="is-link is-block m-4 login-alternativo"
							>
								Fazer login com Office
							</router-link>
						</div>

						<div
							v-if="showLocalLogin"
							class="column is-12 has-text-centered mt-4"
						>
							<button class="button is-primary is-rounded">
								Acessar
							</button>
						</div>

						<b-loading
							v-model="isLoading"
							:can-cancel="true"
						/>
					</div>
				</form>

				<div
					v-if="showOfficeLogin"
					class="column is-12 has-text-centered mt-4"
				>
					<button
						class="button is-primary is-rounded"
						@click="loginOffice"
					>
						Login Office
					</button>
				</div>

				<FaleConosco />
			</div>
		</div>

		<ModalNewLogin
			v-if="showOfficeLogin && !isLoading"
		/>

		<Recaptcha ref="grecaptcha" />
	</div>
</template>

<script>
import UIInput from '@components/uiinput';
import ModalNewLogin from '@components/login/ModalNewLogin';
import { required, minLength } from 'vuelidate/lib/validators';
import { PublicClientApplication } from '@azure/msal-browser';
import { validCPF } from '@commons/validations';
import { isDev, isQea, isPrd } from '@commons/enviroment';
import FaleConosco from '@components/login/FaleConosco';
import Recaptcha from '@components/Recaptcha';
const forceLoginLocal = $route => $route.path === '/login/matricula';


export default {
	name: 'LoginHome',

	components:{
		UIInput,
		ModalNewLogin,
		FaleConosco,
		Recaptcha,
	},

	data() {
		const allowLocalLogin = (isDev || isQea)
			|| forceLoginLocal(this.$route);

		const allowOfficeLogin = this.$store.state.ie.authOffice;

		return {
			isLoading: false,
			labelMatricula: 'Número da matrícula',
			form:{
				user: '',
				cpf: '',
				password: '',
				captcha: '',
			},
			showHelpBox: false,
			browserWidth: null,
			enviroment: {
				isPrd,
			},
			allowLocalLogin,
			allowOfficeLogin,
			account: {},
			accessToken: null,
			$msalInstance: null,
		}
	},

	computed: {
		showLocalLogin() {
			return this.allowLocalLogin
				|| !this.allowOfficeLogin;
		},

		showOfficeLogin() {
			return this.allowOfficeLogin
				&& !this.allowLocalLogin;
		},

		forceLoginLocal() {
			return forceLoginLocal(this.$route);
		},
	},

	async created() {
		this.$msalInstance = new PublicClientApplication(
			this.$store.state.msalConfig,
		);
	},

	mounted() {
		let recaptchaScript = document.createElement('script')
		recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
		document.head.appendChild(recaptchaScript);
		this.RedirectOffice();

		if (this.$store.state.ie?.sigla === 'ebr') {
			this.labelMatricula = 'Número da matrícula ou CPF'
			this.allowOfficeLogin = false
		}
	},

	methods: {
		async handleLoginSubmit() {
			if (this.showLocalLogin) {
				return this.loginLocal();
			}

			if (this.showOfficeLogin) {
				return this.loginOffice();
			}
		},

		async loginLocal() {
			this.isLoading = true;

			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.isLoading = false;

				this.toastOpen('Corrija os erros antes de prosseguir', 'is-danger');

				return;
			}

			try {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} catch (error) {
				this.toastOpen('Recaptcha inválido!', 'is-danger');

				return;
			}

			let requestBody = {
				login: this.form.user || this.form.cpf,
				senha: btoa(this.form.password),
				captcha: this.form.captcha,
				user_type: 'A'
			};

			requestBody = new URLSearchParams(Object.entries(requestBody)).toString();

			this.$store.dispatch('sessao/login', requestBody)
				.then(() => {
					this.$router.push({name: 'home'});
				})
				.catch(() => {
					this.toastOpen('Usuário ou senha inválido', 'is-danger');
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		sendToWhatsApp() {
			window.open(
				'https://api.whatsapp.com/send?phone=551132987730&text=Oi Estudante! Clique em enviar para falar conosco!',
				'_blank'
			);
		},

		async handleResponse(response) {
			if (response !== null) {
				this.account = response.account;
				this.accessToken = response.accessToken;
				await this.SignInPortal();
			} else {
				const currentAccounts = this.$msalInstance.getAllAccounts();
				if (currentAccounts.length === 0) {
					this.$msalInstance.loginRedirect(["User.ReadWrite"]);
				} else if (currentAccounts.length > 0 ) {
					this.account = currentAccounts[0];
					await this.SignInPortal();
				}
			}
		},

		async loginOffice() {
			this.isLoading = true;

			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.isLoading = false;

				this.toastOpen('Corrija os erros antes de prosseguir', 'is-danger');

				return;
			}

			await this.$msalInstance.handleRedirectPromise().then();
			const user = this.form.user || this.form.cpf.replace(/[.-]/g, '');
			const request = {
				loginHint: user + this.$store.state.emailLoginDomain
			};
			await this.$msalInstance.acquireTokenRedirect(request);
		},

		async SignInPortal() {
			this.isLoading = true;
			let requestBody = {
				accessToken: this.accessToken,
			};

			requestBody = new URLSearchParams(Object.entries(requestBody)).toString();
			await this.$store.dispatch('sessao/loginOffice', requestBody)
				.then(() => {
					this.$router.push({name: 'home'});
				}, () => {
					this.isLoading = false;
					this.toastOpen('Usuário ou senha inválido', 'is-danger');
				});
		},

		async RedirectOffice() {
			const hash = this.$route.hash;
			if(hash.includes('#code=') && hash.includes('&session_state=')) {
				this.isLoading = true;
				this.$msalInstance.handleRedirectPromise().then(this.handleResponse);
			} else {
				const query = this.$route.query;
				const hashQuery = String(hash).substring(1).split('&')
					.reduce((acc, cur) => {
						const [ key, value ] = cur.split('=', 2);
						acc[decodeURIComponent(key)] = decodeURIComponent(value || '');
						return acc;
					}, {});
				const accessToken = query.access_token
					?? query.accessToken
					?? hashQuery.access_token
					?? hashQuery.accessToken
					?? null;

				if (accessToken) {
					this.handleResponse({
						accessToken,
						account: '',
					});
				}
			}
		},

		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},
	},

	validations() {
		if (this.allowOfficeLogin && !this.allowLocalLogin) {
			return {
				form: {
					cpf: {
						required,
						validCPF,
					},
				},
			};
		}

		return {
			form: {
				user: {
					required,
				},

				password: {
					required,
					minLength,
				},
			},
		};
	},
}
</script>

<style lang="scss" scoped>
* {
	--dark-blue: #26496C;
	--dark-blue-hover: #2271C1;
}

::v-deep {
	a.login-alternativo {
		color: var(--dark-blue) !important;

		&:hover {
			color: var(--dark-blue-hover) !important;
		}
	}
}
</style>
